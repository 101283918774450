import React, { useState } from "react";
import axios from "axios";
import FormData from "form-data";
import "./App.css";
import logoVoltText from "../src/components/img/logoVoltText.svg";
import seaOriginal from '../src/components/img/seal_original.png';
import seaVolt from '../src/components/img/seal_volt.png';

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

const App = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [processedImage, setProcessedImage] = useState(null);

  const [email, setEmail ] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [subConfirm, setSubConfirm] = useState(null);


  const handleChangeEmail = (event) => {
      const email = event.target.value;
      setEmail(email);
      console.log(email);
  };

  const handleChangeWallet = (event) => {
      const wallet = event.target.value;
      setWallet(wallet);
  };

  const handleSubmit = (event) => {
      event.preventDefault();
      axios.get('https://www.degendistillery.io/rest/api/postSubscription.php?email='+email+"&wallet="+wallet+"&source=Volt")
      .then(res=> {
          console.log(res);
          setSubConfirm("Success!")
      })
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleRemoveBackground = () => {
    if (!selectedFile) {
      console.error("No file selected.");
      return;
    }

    const formData = new FormData();
    formData.append("size", "auto");
    formData.append("image_file", selectedFile);
    formData.append("bg_color", "#d6ff01");

    const headers = {
      "Content-Type": "multipart/form-data",
      "X-Api-Key": "8qg3QU8Wx4HMcbWrdu8b3qwa",
    };

    axios({
      method: "post",
      url: "https://api.remove.bg/v1.0/removebg",
      data: formData,
      responseType: "arraybuffer",
      headers: headers,
      encoding: null,
    })
      .then((response) => {
        if (response.status !== 200) {
          console.error("Error:", response.status, response.statusText);
          return;
        }
        const processedImageBuffer = response.data;
        const processedImageBase64 = btoa(
          new Uint8Array(processedImageBuffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        setProcessedImage(processedImageBase64);
      })
      .catch((error) => {
        console.error("Request failed:", error);
      });
  };

  const handleDownload = () => {
    if (!processedImage) {
      console.error("No processed image available for download.");
      return;
    }

    const backgroundColor = "#d6ff01";
    const dataURI = `data:image/png;base64,${processedImage}`;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const img = new Image();
    img.src = dataURI;
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image on the canvas
      ctx.drawImage(img, 0, 0);

      // Change the background color
      ctx.globalCompositeOperation = "destination-over";
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Convert the canvas content to a data URI
      const processedDataURI = canvas.toDataURL("image/png");

      // Create a temporary link to download the processed image
      const link = document.createElement("a");
      link.href = processedDataURI;
      link.download = "voltAvatar.png";
      link.click();
    };
  };

  return (
    <div className="App">
      <Container>
        <Row className="text-center my-5">
          <Col><img src={logoVoltText} alt="logo volt black" width={"300px"} /></Col>
        </Row> 
        <Row className="night__Container">
          <>
          {processedImage 
          ?
            <Col xs={12} className="text-center">
              <div>
                <div>
                  <img
                    src={`data:image/png;base64,${processedImage}`}
                    alt="Processed"
                    style={{maxWidth:"100%"}}
                  />
                </div>
                <Button onClick={handleDownload} className="degenButton m.3">
                  Download
                </Button>
                <Button onClick={() => window.open('https://www.degendistillery.com', '_blank')} className="degenButton m-3">
                  Degen Distillery
                </Button>
              </div>
            </Col>
          : 
          <>
          <Col xs={12} className="text-center">
            <h1 className="night_title">Get VOLTED to confirm your Guestlist submission</h1>
            <ol style={{textAlign: 'left'}}>
              <li>Upload your profile picture</li>
              <li>Get VOLTED </li>
              <li>Tag us in a post on X or Instagram with your VOLTED profile picture</li>
              <li>Join our Discord <a href="https://discord.gg/degendistillery" target="_blank" rel="noreferrer" style={{color: '#ffffff'}}>here</a></li>
              <li>Open a support ticket with a screenshot</li>
            </ol>
            <Form>
              <Form.Group className="mb-3" controlId="file">
                <Form.Control
                  className="textInput"
                  type="file"
                  placeholder="upload your image"
                  onChange={handleFileChange}
                />
              </Form.Group>
            </Form>
          </Col>
          {selectedFile && (
            <Col xs={12} className="mt-3 text-center">
              <Button
                onClick={handleRemoveBackground}
                className="degenButton m-3"
              >
                Volt it!
              </Button>
              <Button onClick={() => window.open('https://www.degendistillery.com', '_blank')} className="degenButton m-3">
                  Degen Distillery
              </Button>            
            </Col>
          )}
          </>
          }
          </>
        </Row>
      </Container> 
    </div>
  );
};

export default App;